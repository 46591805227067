import React, { useRef, useCallback, useContext, useMemo } from 'react'
import { Props, components } from 'react-select'
import { grayLight } from 'components/style/colors'
import { StyledAsyncSelect, StyledSelect } from 'components/Select2/style'
import { MobileContext } from 'src/Context'
import styled from 'styled-components'
import { MobileMenuItemSelect, MobileSelect } from 'components/MobileSelect'
import i18n from 'src/localization'
import { useTranslation } from 'react-i18next'

const SelectContainer = props => {
  return (
    <div data-cy={props['data-cy']}>
      <components.SelectContainer {...props} />
    </div>
  )
}

const IndicatorSeparator = containerProps => (
  <components.IndicatorSeparator {...containerProps} />
)

const DropdownIndicator = containerProps => (
  <components.DropdownIndicator {...containerProps} />
)

const SelectItem = props => {
  return (
    <components.Option {...props} data-cy={`select-item-${props.data?.id}`} />
  )
}

const portalTarget = document.getElementById('react-select-portal-target')
export const SelectMobileContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.light};
  border-radius: 4px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 0px;
  min-width: 120px;
  min-height: 0px;
  border: 1px solid ${props => props.theme.colors.outline};
`

export const SelectMobileWrapper = styled.div`
  padding: 2px;
  flex-wrap: nowrap;
  overflow: auto;
  box-sizing: border-box;
  margin: 4px;
  border: 0px;
  position: relative;
  flex: 1;
  align-items: center;
  display: flex;
`

export const SelectMobilePlaceholder = styled.div`
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
`

export const SelectMobileIndicators = styled.div`
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
`

export const SelectMobileIndicatorDivider = styled.span`
  align-self: stretch;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
`

export const SelectMobileIndicator = styled.div`
  color: hsl(0, 0%, 80%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
`

interface IProps extends Props<any, boolean> {
  isAsync?: boolean
  formatValue?: (option) => any
  // onValueChanged?: (
  //   newValue: any,
  //   values: any,
  //   formik: FormikContextType<any>
  // ) => any;
  parseValue?: (value) => any
  loadOptions?: (inputValue?: any, callback?: () => any) => any
  options: any[]
  error?: string | null
  allowSelectAll?: boolean
  isColor?: boolean
  isMulti?: boolean
  groups?: any[]
  isMenuItem?: boolean
  mobileStyles?: any
  isServiceSelect?: boolean
  isEmployeeSelect?: boolean
  hasPlusMinusButtons?: boolean
  minusBtnDisabled?: boolean
  minusBtnOnClick?: any
  plusBtnDisabled?: boolean
  plusBtnOnClick?: any
  selectMinWidth?: string
  hideSeparator?: boolean
  hideChevron?: boolean
  isDisabled?: boolean
  mobileClearValue?: () => any
  renderMobileMultiValues?: (values: { id: string; name: string }[]) => any
}

const defaultGetOptionLabel = o => o.name || o.title
const defaultGetOptionValue = o => o.id
/**
 * The select component, wrapper for the react-select with Zoyya styling applied
 */
export const Select = (props: IProps) => {
  const { t } = useTranslation()

  const {
    components,
    styles,
    formatOptionLabel,
    onChange,
    selectMinWidth,
    hideChevron,
    hideSeparator,
    isDisabled,
    placeholder = t('translation.DurationSelect.select'),
    renderMobileMultiValues,
    mobileClearValue,
  } = props
  const selectRef = useRef<any>()

  const dataCy = props['data-cy']
  const isMobile = useContext(MobileContext)

  const Container = useCallback(
    containerProps => <SelectContainer {...containerProps} data-cy={dataCy} />,
    [dataCy]
  )

  const Option = useCallback(
    selectItemProps => <SelectItem {...selectItemProps} data-cy={dataCy} />,
    [dataCy]
  )

  const customComponents = useMemo(() => {
    return {
      ...components,
      SelectContainer: Container,
      Option: components?.Option || Option,
      IndicatorSeparator: hideSeparator ? null : IndicatorSeparator,
      DropdownIndicator: hideChevron ? null : DropdownIndicator,
    }
  }, [components, Container, Option, hideChevron, hideSeparator])

  const optionLabel = useCallback(
    (opt, meta) => {
      return formatOptionLabel
        ? formatOptionLabel(opt, meta)
        : opt?.name || opt?.title || opt?.firstName || opt?.toString()
    },
    [formatOptionLabel]
  )

  const handleChange = useCallback(
    (value, meta) => {
      onChange?.(value, meta)
    },
    [onChange]
  )

  const customStyles = useMemo(
    () => ({
      ...styles,
      menuPortal: (provided, state) => ({
        ...provided,
        zIndex: 1000,
      }),
      control: (provided, state) => {
        const providedStyled = styles

        return providedStyled && providedStyled.control
          ? providedStyled.control(provided, state)
          : {
              ...provided,
              padding: '0',
            }
      },
      singleValue: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.singleValue
          ? providedStyled.singleValue(provided, state)
          : {
              ...provided,
            }
      },
      container: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.container
          ? providedStyled.container(provided, state)
          : {
              ...provided,
            }
      },
      input: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.input
          ? providedStyled.input(provided, state)
          : {
              ...provided,
              border: '0px',
              padding: '0px',
            }
      },
      valueContainer: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.valueContainer
          ? providedStyled.valueContainer(provided, state)
          : {
              ...provided,
              margin: '4px',
              border: '0px',
            }
      },
      menu: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.menu
          ? providedStyled.menu(provided, state)
          : {
              ...provided,
            }
      },
      option: (provided, state) => {
        const providedStyled = styles
        return providedStyled && providedStyled.option
          ? providedStyled.option(provided, state)
          : {
              ...provided,
              color: 'black',
              cursor: 'pointer',
              background: state.background ? grayLight : 'white',
            }
      },
    }),
    [styles]
  )

  return !isMobile ? (
    props.isAsync ? (
      <StyledAsyncSelect
        ref={selectRef}
        {...props}
        formatOptionLabel={optionLabel}
        onInputChange={props.onInputChange}
        classNamePrefix="react-select"
        components={customComponents}
        onChange={handleChange}
        openMenuOnClick={!isMobile && props.openMenuOnClick}
        additional={{
          page: 0,
        }}
        loadOptions={props.loadOptions}
        menuPortalTarget={portalTarget}
        styles={customStyles}
      />
    ) : props.hasPlusMinusButtons ? (
      <div className="flex items-center justify-between">
        <button
          disabled={props.minusBtnDisabled}
          onClick={props.minusBtnOnClick}
          type="button"
          className={`w-[40px] h-[40px] rounded-[4px] bg-zoyya-grayDarker ${
            props.minusBtnDisabled ? 'opacity-[0.3]' : 'opacity-[1]'
          }`}
        >
          <span className="text-[20px]">-</span>
        </button>
        <StyledSelect
          ref={selectRef}
          {...props}
          openMenuOnClick={!isMobile}
          placeholder={placeholder}
          formatOptionLabel={optionLabel}
          classNamePrefix="react-select"
          onChange={handleChange}
          data-cy="TESTING"
          components={customComponents}
          menuPortalTarget={portalTarget}
          disabled={isDisabled}
          isDisabled={isDisabled}
        />
        <button
          disabled={props.plusBtnDisabled}
          onClick={props.plusBtnOnClick}
          type="button"
          className={`w-[40px] h-[40px] rounded-[4px] bg-zoyya-grayDarker ${
            props.plusBtnDisabled ? 'opacity-[0.3]' : 'opacity-[1]'
          }`}
        >
          <span className="text-[20px]">+</span>
        </button>
      </div>
    ) : (
      <StyledSelect
        ref={selectRef}
        {...props}
        openMenuOnClick={!isMobile}
        formatOptionLabel={optionLabel}
        classNamePrefix="react-select"
        onChange={handleChange}
        data-cy="TESTING"
        components={customComponents}
        placeholder={placeholder}
        menuPortalTarget={portalTarget}
        styles={customStyles}
        selectMinWidth={props.selectMinWidth}
      />
    )
  ) : props.isMenuItem ? (
    <MobileMenuItemSelect
      ref={selectRef}
      {...props}
      formatOptionLabel={optionLabel}
      onInputChange={props.onInputChange}
      classNamePrefix="react-select"
      components={{
        ...props.components,
        SelectContainer: Container,
      }}
      onChange={handleChange}
      placeholder={placeholder}
      openMenuOnClick={!isMobile}
      mobileClearValue={mobileClearValue}
      additional={{
        page: 0,
      }}
    />
  ) : props.hasPlusMinusButtons ? (
    <div className="flex items-center justify-between">
      <button
        disabled={props.minusBtnDisabled}
        onClick={props.minusBtnOnClick}
        type="button"
        className={`w-[40px] h-[40px] rounded-[4px] bg-zoyya-grayDarker ${
          props.minusBtnDisabled ? 'opacity-[0.3]' : 'opacity-[1]'
        }`}
      >
        <span className="text-[20px]">-</span>
      </button>
      <MobileSelect
        ref={selectRef}
        {...props}
        formatOptionLabel={optionLabel}
        placeholder={placeholder}
        onInputChange={props.onInputChange}
        classNamePrefix="react-select"
        components={{
          ...props.components,
          SelectContainer: Container,
        }}
        onChange={handleChange}
        openMenuOnClick={!isMobile}
        style={props.mobileStyles}
        additional={{
          page: 0,
        }}
        minWidth={true}
        renderMultiValues={renderMobileMultiValues}
      />
      <button
        disabled={props.plusBtnDisabled}
        onClick={props.plusBtnOnClick}
        type="button"
        className={`w-[40px] h-[40px] rounded-[4px] bg-zoyya-grayDarker ${
          props.plusBtnDisabled ? 'opacity-[0.3]' : 'opacity-[1]'
        }`}
      >
        <span className="text-[20px]">+</span>
      </button>
    </div>
  ) : (
    <MobileSelect
      ref={selectRef}
      {...props}
      formatOptionLabel={optionLabel}
      placeholder={placeholder}
      onInputChange={props.onInputChange}
      classNamePrefix="react-select"
      components={{
        ...props.components,
        SelectContainer: Container,
      }}
      onChange={handleChange}
      openMenuOnClick={!isMobile}
      style={props.mobileStyles}
      renderMultiValues={renderMobileMultiValues}
      mobileClearValue={mobileClearValue}
      additional={{
        page: 0,
      }}
    />
  )
}

Select.defaultProps = {
  isClearable: true,
  isSearchable: true,
  getOptionLabel: defaultGetOptionLabel,
  getOptionValue: defaultGetOptionValue,
  controlShouldRenderValue: true,
}
