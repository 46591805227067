import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import {
  StyledSelectValueContainer,
  StyledDropDownContainer,
  StyledMenuListContainer,
} from './style'
import { useInView } from 'react-intersection-observer'
import { debounce, isBoolean, some } from 'lodash'
import { ChevronDown } from '../Icon/Icons/ChewronDownLight'
import { useTranslation } from 'react-i18next'
import { useOnClickOutsideWithoutTriggeringAction } from 'src/helpers'
import Search from 'components/Icon/Icons/Search'
import Input from 'components/Input'
import Cross from '../Icon/Icons/Cross'
const portalTarget = document.getElementById(
  //"mobile-select-portal-target"
  'body'
) as any

const SHOW_FILTER_INPUT_IF_MIN_OPTIONS = 10

export const MobileSelect = props => {
  const {
    options,
    onChange,
    isMulti,
    value,
    components,
    getOptionLabel,
    formatOptionLabel,
    isAsync,
    loadOptions,
    controlShouldRenderValue,
    isSearchable,
    isDisabled,
    placeholder,
    customComponent,
    name,
    hideChevron,
    style,
    isServiceSelect,
    isEmployeeSelect,
    minWidth,
    renderMultiValues,
    isClearable,
    mobileClearValue,
  } = props
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const itemsNode = useRef()
  const handleOpenSelect = useCallback(
    e => {
      if (isDisabled) return
      setIsOpen(true)
    },
    [isDisabled]
  )

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const handleCloseDropDown = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      setIsOpen(false)
    },
    [setIsOpen]
  )

  const OptionPresenter = components?.MobileOption

  const renderMenuItem = useCallback(
    opt => {
      if (!opt) return null
      return OptionPresenter ? (
        <OptionPresenter
          selectProps={{ value: selectedOption }}
          data={opt}
        ></OptionPresenter>
      ) : formatOptionLabel ? (
        formatOptionLabel(opt)
      ) : (
        opt.name || opt.title
      )
    },
    [OptionPresenter, formatOptionLabel, selectedOption]
  )

  const handleOptionSelected = option => {
    const newSelectionAdd = isMulti
      ? [...(selectedOption ?? []), option]
      : option
    const newSelectionRemove = isMulti
      ? selectedOption?.filter(x => x.id !== option.id)
      : undefined

    if (isMulti) {
      if (!value || !value.length) {
        setSelectedOption(newSelectionAdd)
        onChange?.(newSelectionAdd)
      } else {
        if (isBoolean(option?.isSelected)) {
          if (option.isSelected) {
            setSelectedOption(newSelectionRemove)
            onChange?.(newSelectionRemove)
          } else {
            setSelectedOption(newSelectionAdd)
            onChange?.(newSelectionAdd)
          }
        } else {
          if (
            !!value?.find(x => {
              return option?.id === x.id
            })
          ) {
            setSelectedOption(newSelectionRemove)
            onChange?.(newSelectionRemove)
          } else {
            setSelectedOption(newSelectionAdd)
            onChange?.(newSelectionAdd)
          }
        }
      }
    } else {
      if (option?.id === value?.id) {
        // do nothing
      } else {
        setSelectedOption(newSelectionAdd)
        onChange?.(newSelectionAdd)
      }
    }

    setIsOpen(false)
  }

  const scrollToItem = (parent, child) => {
    if (!parent || !child) return
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect()
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    }

    // Where is the child
    const childRect = child.getBoundingClientRect()
    // Is the child viewable?
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
    }
  }

  return (
    <div
      onClick={handleOpenSelect}
      tabIndex={0}
      data-cy={`mobileSelect_${name}`}
      className={`${minWidth ? 'grow m-[0_10px]' : ''} ${
        isDisabled ? 'is-disabled' : ''
      }`}
    >
      {customComponent ? (
        <div className="md:w-full md:p-[0px] md:flex md:justify-between">
          {customComponent}
          {!hideChevron && (
            <span className="flex justify-center self-center pr-[8px]">
              <ChevronDown color={'grayDark'} isChevron />
            </span>
          )}
        </div>
      ) : (
        <StyledSelectValueContainer
          disabled={isDisabled}
          hideBorder={!!customComponent && !!selectedOption}
          style={style}
        >
          <div className="flex flex-row grow items-center justify-start content-[stretch] self-stretch flex-wrap">
            {controlShouldRenderValue ? (
              isMulti ? (
                renderMultiValues && selectedOption?.length ? (
                  renderMultiValues(selectedOption)
                ) : (
                  selectedOption?.map((option, idx) => (
                    <div data-cy={'mobileSelect_value'}>
                      {option.name +
                        (idx < selectedOption?.length - 1 ? ', ' : '')}
                    </div>
                  ))
                )
              ) : selectedOption ? (
                <div data-cy={'mobileSelect_value'}>
                  {selectedOption?.isInactive ? (
                    <>
                      <span
                        className={`flex-[2] truncate inline mr-[20px] ${
                          selectedOption?.isInactive
                            ? 'text-zoyya-accent5'
                            : 'text-zoyya-text'
                        }`}
                      >
                        {formatOptionLabel(selectedOption)}
                      </span>
                      <span
                        className={`flex-1 p-[2px_7px] text-[10px] rounded-[4px] text-zoyya-light truncate inline ${
                          selectedOption?.isInactive
                            ? 'bg-zoyya-accent5'
                            : 'bg-zoyya-accent4'
                        }`}
                      >
                        {t('translation.StartTimeSelect.label-off-duty')}
                      </span>
                    </>
                  ) : (
                    <div>
                      {selectedOption?.isBusy ? (
                        <>
                          <span
                            className={`flex-[2] truncate inline mr-[20px] ${
                              selectedOption?.isBusy
                                ? 'text-zoyya-accent4'
                                : 'text-zoyya-text'
                            }`}
                          >
                            {formatOptionLabel(selectedOption)}
                          </span>
                          <span
                            className={`flex-1 p-[2px_7px] text-[10px] rounded-[4px] text-zoyya-light truncate inline bg-zoyya-accent4`}
                          >
                            {t('translation.StartTimeSelect.label-busy')}
                          </span>
                        </>
                      ) : (
                        formatOptionLabel(selectedOption)
                      )}
                    </div>
                  )}
                </div>
              ) : (
                placeholder
              )
            ) : (
              placeholder
            )}
          </div>

          <div className="inline-block border-[none] outline-[none] min-w-[0px] self-start grow-0">
            <input
              className="border-[none] outline-[none] w-[2px] min-w-[0px] self-start grow-0 box-content"
              name="mobileSelect_input"
              disabled={isDisabled}
              readOnly={true}
            />
          </div>
          {isClearable && mobileClearValue ? (
            <span
              className="flex justify-center self-center pr-[8px]"
              onClick={e => {
                e.stopPropagation()
                mobileClearValue()
                setSelectedOption(null)
              }}
            >
              <Cross color={'grayDark'} size="extraSmall" stroke={3} />
            </span>
          ) : null}
          {!hideChevron && (
            <span className="flex justify-center self-center pr-[8px]">
              <ChevronDown color={'grayDark'} isChevron />
            </span>
          )}
        </StyledSelectValueContainer>
      )}

      {isOpen ? (
        <SelectDropDown
          formatOptionLabel={renderMenuItem}
          getOptionLabel={getOptionLabel}
          onSelected={handleOptionSelected}
          options={options}
          closeDropDown={handleCloseDropDown}
          isMulti={isMulti}
          value={value}
          components={components}
          isAsync={isAsync}
          loadOptions={loadOptions}
          isSearchable={isSearchable}
          ref={itemsNode}
          scrollToItem={scrollToItem}
          isServiceSelect={isServiceSelect}
          isEmployeeSelect={isEmployeeSelect}
        />
      ) : null}
    </div>
  )
}

MobileSelect.defaultProps = {
  isSearchable: true,
}
export const MobileMenuItemSelect = props => {
  const {
    options,
    onChange,
    isMulti,
    value,
    components,
    getOptionLabel,
    formatOptionLabel,
    isAsync,
    loadOptions,
    controlShouldRenderValue,
    isSearchable,
    isDisabled,
    placeholder,
    name,
    isEmployeeSelect,
  } = props

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const itemsNode = useRef()
  const handleOpenSelect = useCallback(
    e => {
      if (isDisabled) return
      setIsOpen(true)
    },
    [isDisabled]
  )

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const handleCloseDropDown = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      setIsOpen(false)
    },
    [setIsOpen]
  )

  const OptionPresenter = components?.MobileOption

  const renderMenuItem = useCallback(
    opt => {
      if (!opt) return null
      return OptionPresenter ? (
        <OptionPresenter
          selectProps={{ value: selectedOption }}
          data={opt}
        ></OptionPresenter>
      ) : formatOptionLabel ? (
        formatOptionLabel(opt)
      ) : (
        opt.name || opt.title
      )
    },
    [OptionPresenter, formatOptionLabel, selectedOption]
  )

  const handleOptionSelected = option => {
    const newSelectionAdd = isMulti
      ? [...(selectedOption ?? []), option]
      : option
    const newSelectionRemove = isMulti
      ? selectedOption?.filter(x => x.id !== option.id)
      : undefined

    if ((value && some(value, { id: option.id })) || option.isSelected) {
      setSelectedOption(newSelectionRemove)
      onChange?.(newSelectionRemove)
    } else {
      setSelectedOption(newSelectionAdd)
      onChange?.(newSelectionAdd)
    }

    //if (!isMulti) {
    setIsOpen(false)
    //}
  }

  const scrollToItem = (parent, child) => {
    if (!parent || !child) return
    // Where is the parent on page
    const parentRect = parent.getBoundingClientRect()
    // What can you see?
    const parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    }

    // Where is the child
    const childRect = child.getBoundingClientRect()
    // Is the child viewable?
    const isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
    }
  }

  return (
    <div
      onClick={handleOpenSelect}
      tabIndex={0}
      className={isDisabled ? 'is-disabled' : ''}
      data-cy={`mobileSelect_${name}`}
    >
      <div className="h-[50px] flex items-center">
        <div className="text-[14px] font-medium">
          {controlShouldRenderValue ? (
            isMulti ? (
              selectedOption?.map((option, idx) => (
                <div data-cy={'mobileSelect_value'}>
                  {option.name + (idx < selectedOption?.length - 1 ? ', ' : '')}
                </div>
              ))
            ) : selectedOption ? (
              <div data-cy={'mobileSelect_value'}>
                {selectedOption?.isInactive ? (
                  <>
                    <span
                      className={`flex-[2] truncate inline mr-[20px] ${
                        selectedOption?.isInactive
                          ? 'text-zoyya-accent5'
                          : 'text-zoyya-text'
                      }`}
                    >
                      {formatOptionLabel(selectedOption)}
                    </span>
                    <span
                      className={`flex-1 p-[2px_7px] text-[10px] rounded-[4px] text-zoyya-light truncate inline ${
                        selectedOption?.isInactive
                          ? 'bg-zoyya-accent5'
                          : 'bg-zoyya-accent4'
                      }`}
                    >
                      {t('translation.StartTimeSelect.label-off-duty')}
                    </span>
                  </>
                ) : (
                  <div>
                    {selectedOption?.isBusy ? (
                      <>
                        <span
                          className={`flex-[2] truncate inline mr-[20px] ${
                            selectedOption?.isBusy
                              ? 'text-zoyya-accent4'
                              : 'text-zoyya-text'
                          }`}
                        >
                          {formatOptionLabel(selectedOption)}
                        </span>
                        <span
                          className={`flex-1 p-[2px_7px] text-[10px] rounded-[4px] truncate inline text-zoyya-light bg-zoyya-accent4`}
                        >
                          {t('translation.StartTimeSelect.label-busy')}
                        </span>
                      </>
                    ) : (
                      formatOptionLabel(selectedOption)
                    )}
                  </div>
                )}
              </div>
            ) : (
              placeholder
            )
          ) : (
            placeholder
          )}
        </div>
        <div className="inline-block border-[none] outline-[none] min-w-[0px] self-start grow-0">
          <input
            className="border-[none] outline-[none] w-[2px] min-w-[0px] self-start grow-0 box-content"
            name="mobileSelect_input"
            disabled={isDisabled}
          />
        </div>
      </div>
      {isOpen ? (
        <SelectDropDown
          formatOptionLabel={renderMenuItem}
          getOptionLabel={getOptionLabel}
          onSelected={handleOptionSelected}
          options={options}
          closeDropDown={handleCloseDropDown}
          isMulti={isMulti}
          value={value}
          components={components}
          isAsync={isAsync}
          loadOptions={loadOptions}
          isSearchable={isSearchable}
          ref={itemsNode}
          scrollToItem={scrollToItem}
          isEmployeeSelect={isEmployeeSelect}
        />
      ) : null}
    </div>
  )
}

MobileMenuItemSelect.defaultProps = {
  isSearchable: true,
}
const SelectDropDown = React.forwardRef((props: any, ref: any) => {
  const {
    closeDropDown,
    options,
    onSelected,
    formatOptionLabel,
    getOptionLabel,
    isMulti,
    isAsync,
    loadOptions,
    components,
    isSearchable,
    scrollToItem,
    isServiceSelect,
    isEmployeeSelect,
  } = props
  const [currentPage, setCurrentPage] = useState(0)
  const [filterText, setFilterText] = useState('')
  const [renderOptions, setRenderOptions] = useState<any[]>(options || [])
  const [lastFetchData, setLastFetchData] = useState<any>()
  const containerRef = useRef<any>()
  const [lastMenuItemRef, lastMenuItemInView] = useInView({
    root: containerRef.current,
  })

  // when menu is opened, scroll to the selected option
  useEffect(() => {
    const selectedOptionNode = document.getElementById(
      `dropdownTime-${props.value?.id}`
    )
    if (ref.current && selectedOptionNode) {
      scrollToItem(ref.current, selectedOptionNode)
    }
  }, [ref, options])

  const asyncLoadData = useCallback(
    async (text, page) => {
      if (!isAsync || !loadOptions) return
      const data = await loadOptions(text, [], { page })
      setRenderOptions(loadedOptions => {
        return page === 0 ? data?.options : [...loadedOptions, ...data?.options]
      })
      setLastFetchData(data)
    },
    [isAsync, loadOptions, setRenderOptions]
  )
  useEffect(() => {
    if (!isAsync || !lastMenuItemInView || !lastFetchData?.hasMore) return
    setCurrentPage(page => page + 1)
  }, [lastMenuItemInView, lastFetchData, isAsync])

  useEffect(() => {
    if (!isAsync || currentPage === 0) return
    asyncLoadData(filterText, currentPage)
  }, [isAsync, currentPage, asyncLoadData, filterText])
  useEffect(() => {
    setCurrentPage(0)
    setFilterText('')
    asyncLoadData('', 0)
  }, [asyncLoadData])

  const debouncedFiltering = useCallback(
    debounce((text: any) => {
      if (isAsync) {
        setCurrentPage(0)
        asyncLoadData(text, 0)
      } else {
        const filteredOptions = filterOptionsByText(
          options,
          text,
          getOptionLabel,
          isEmployeeSelect
        )
        setRenderOptions(filteredOptions)
      }
    }, 200),
    [isAsync, options]
  )

  useEffect(() => {
    debouncedFiltering(filterText)
  }, [filterText, debouncedFiltering])

  const handleSelectOption = useCallback(
    (e, option) => {
      e.stopPropagation()
      onSelected(option)
    },
    [onSelected]
  )

  const renderOption = (option, index, prop) => {
    const groupOptions = option.options

    return (
      <div key={option.id || index}>
        {groupOptions ? (
          <GroupOptionPresenter
            group={option}
            formatOptionLabel={formatOptionLabel}
            isMulti={isMulti}
            onOptionClick={handleSelectOption}
          />
        ) : (
          <ValueOptionPresenter
            id={'dropdownTime-' + option.id}
            option={option}
            onClick={e => handleSelectOption(e, option)}
            optionPresenter={components?.Option}
            formatOptionLabel={formatOptionLabel}
            isMulti={isMulti}
          />
        )}
      </div>
    )
  }
  const MenuList = components.MobileMenuList || StyledMenuListContainer
  useOnClickOutsideWithoutTriggeringAction(containerRef, closeDropDown)
  return ReactDOM.createPortal(
    <div className="flex flex-col items-end justify-end h-full w-full absolute z-[10000000000] bg-[#00000080] bottom-0">
      <StyledDropDownContainer ref={containerRef} isSearchable={isSearchable}>
        <div className="h-[50px]" onClick={closeDropDown} />
        {/*  <StyledCloseButton
          onClick={closeDropDown}
          data-cy={'mobileSelect_close'}
        >
          <Cross size="large" />
        </StyledCloseButton> */}
        <div className="w-full lg:w-[auto] flex flex-col flex-1 max-h-full overflow-hidden p-[20px] pb-[0] rounded-[30px_30px_0_0] bg-zoyya-light">
          {isAsync ||
          isMulti ||
          isServiceSelect ||
          (isSearchable &&
            options?.length >= SHOW_FILTER_INPUT_IF_MIN_OPTIONS) ? (
            <div>
              <Input
                name="mobileSelect_filter"
                onChange={e => setFilterText(e.currentTarget.value)}
                value={filterText}
                icon={<Search style={{ strokeWidth: 0, top: 12 }} />}
              />
            </div>
          ) : null}
          <MenuList ref={ref} id={'dropdownTime'}>
            {renderOptions?.map(renderOption)}
            {isAsync && renderOptions?.length ? (
              <div
                ref={lastMenuItemRef}
                style={{ height: '1px', width: '1px' }}
              >
                &nbsp;
              </div>
            ) : null}
          </MenuList>
        </div>
      </StyledDropDownContainer>
    </div>,
    portalTarget
  )
})

const GroupOptionPresenter = props => {
  const { onOptionClick, group, formatOptionLabel, isMulti } = props
  const { options } = group
  return (
    <div className="w-full lg:w-auto flex flex-col max-h-full overflow-hidden">
      <div className="font-medium text-[16px] m-[10px_0_0] text-zoyya-text">
        {group.label || group.title || group.name}
      </div>
      {options.map((o, index) => (
        <ValueOptionPresenter
          option={o}
          key={o?.id || index}
          formatOptionLabel={formatOptionLabel}
          isMulti={isMulti}
          onClick={e => onOptionClick(e, o)}
        />
      ))}
    </div>
  )
}

const ValueOptionPresenter = props => {
  const { onClick, option, isMulti, isSelected, formatOptionLabel, id } = props
  return (
    <div
      className="p-[9px]"
      onClick={onClick}
      id={id}
      data-cy={`mobileSelect_option-${
        option.name || option.title || option.countryName
      }`}
      data-name={'mobileSelect_option'}
    >
      {isMulti && isSelected ? <div>X</div> : null}
      {formatOptionLabel(option)}
    </div>
  )
}

const isOptionVisible = (option, text, getOptionLabel) => {
  const isGroup = !!option.options

  return isGroup
    ? !!option.options.find(e => isOptionVisible(e, text, getOptionLabel))
    : getOptionLabel(option)?.toLowerCase().includes(text)
}

const filterOptionsByText = (
  options,
  text,
  getOptionLabel,
  isEmployeeSelect
) => {
  const normalizedText = text?.toLowerCase()

  return isEmployeeSelect
    ? options?.filter(
        x =>
          x?.firstName?.toLowerCase()?.includes(normalizedText.toLowerCase()) ||
          x?.lastName?.toLowerCase()?.includes(normalizedText.toLowerCase()) ||
          x?.nickName?.toLowerCase()?.includes(normalizedText.toLowerCase()) ||
          x?.name?.toLowerCase()?.includes(normalizedText.toLowerCase())
      )
    : options
        ?.filter(o => isOptionVisible(o, normalizedText, getOptionLabel))
        .map(o => {
          return {
            ...o,
            options: o?.options?.filter(o =>
              isOptionVisible(o, normalizedText, getOptionLabel)
            ),
          }
        })
}
