import { useParams } from 'react-router-dom'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import React, { useContext, useEffect } from 'react'
import { Loader } from 'components'
import { icons } from 'components'
import { useFooter } from 'mobile/Footer'
import { RouterOutput, trpc } from 'src/trpc'
import { useGetOrganizationByIdQuery } from 'src/state/graphql'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import { format } from 'date-fns'
import UpgradePage from 'src/components/UpgradePage'
import { UserContext } from 'src/Context'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'

export const ClientGiftCardsList = ({
  clientId,
  smallerImage = false,
  closeModal,
  isAppointmentModal,
}: {
  clientId?: string
  smallerImage?: boolean
  closeModal?: () => void
  isAppointmentModal?: boolean
}) => {
  const { t, navigateTo } = useSdk()
  const params = useParams<any>()
  const trpcContext = trpc.useContext()

  const { data: orgData, loading: orgLoading } = useGetOrganizationByIdQuery({
    variables: { id: params?.orgId },
  })
  const org = orgData?.organization?.get

  const { data: giftCards, isLoading: giftCardsLoading } =
    trpc.client_getGiftCards.useQuery({
      clientId: clientId ? BigInt(clientId) : BigInt(params.id!),
      locationId: BigInt(params.locationId!),
      orgId: params.orgId!,
    })

  useHeader({
    content: 'Poklon bonovi',
    onGoBack: () => navigateTo.clientDetails(params),
  })

  useFooter({
    showAddButton: false,
  })

  useEffect(() => {
    trpcContext.client_getGiftCards.invalidate()
  }, [])

  const isLoading = orgLoading || giftCardsLoading

  const { setOpenBillingModal } = useContext(UserContext) as {
    setOpenBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  }
  if (!orgLoading && org && org?.subscriptionPlan !== 'advanced') {
    return (
      <UpgradePage
        header={t('translation.Sidebar.label-loyalty')}
        title={t('translation.UpgradePageLoyalty.title')}
        setOpenBillingModal={setOpenBillingModal}
        text={t('translation.UpgradePageLoyalty.description')}
        list={[
          t('translation.UpgradePageLoyalty.bullet1'),
          t('translation.UpgradePageLoyalty.bullet2'),
          t('translation.UpgradePageLoyalty.bullet3'),
        ]}
        image={'/assets/illustrations/giftcards-upgrade.png'}
        isGiftCardsPage
        closeModal={closeModal}
        isAppointmentModal={isAppointmentModal}
        notAvailableMessage="Poklon bonovi su dostupni samo na naprednom planu"
      />
    )
  }

  return (
    <>
      {isLoading ? (
        <Loader isComponent />
      ) : (
        <>
          {clientId ? (
            <div className="lg:hidden text-lg py-4 text-center border-b-1 bg-zoyya-light border-zoyya-border fixed w-full top-0 pl-2 left-0 z-50">
              {t('translation.ClientDetailsMobile.gift-cards')}
            </div>
          ) : null}

          <div className={`m-5 ${clientId ? 'pt-14 lg:pt-0 pb-5' : ''}`}>
            <GiftCardsList
              giftCards={giftCards || []}
              smallerImage={smallerImage}
              isAppointmentModal={isAppointmentModal}
            />
          </div>
        </>
      )}
    </>
  )
}

export const GiftCardsList = ({
  giftCards,
  smallerImage,
  isAppointmentModal,
}: {
  giftCards: RouterOutput['client_getGiftCards']
  smallerImage: boolean
  isAppointmentModal?: boolean
}) => {
  const { navigateTo } = useSdk()
  return (
    <>
      {giftCards?.length ? (
        giftCards.map(giftCard => {
          const isGiftCardUsed =
            giftCard?.giftCardType === 'VOUCHER'
              ? giftCard.giftCardTotalQuantity === 0
              : giftCard.balance === 0
          return (
            <div
              key={giftCard.id.toString()}
              className="mb-5 rounded-lg border px-4 py-3 relative"
            >
              <div className="flex flex-col gap-3 md:flex-row md:gap-5">
                <div className="md:hidden">
                  <Status giftCard={giftCard} isGiftCardUsed={isGiftCardUsed} />
                </div>

                <div
                  className={`relative h-fit shrink-0 rounded-sm ${
                    smallerImage ? 'md:w-[250px]' : 'md:w-[300px]'
                  }`}
                >
                  <img
                    className="h-auto w-full object-cover"
                    src={giftCard.imageUrl}
                  />
                  <div className="absolute bottom-0 right-0 flex flex-wrap items-center gap-2 p-2">
                    <div className="flex rounded-sm bg-black/50 px-2 py-[2px] text-sm leading-[19px] text-white">
                      {giftCard.giftCardType === 'GIFTCARD'
                        ? 'Poklon bon'
                        : 'Paket usluga'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-1 flex-col gap-1">
                  <div className="flex items-center justify-between gap-3">
                    <p className="text-lg font-medium">{giftCard.name}</p>
                    <div className="hidden md:block">
                      <Status
                        giftCard={giftCard}
                        isGiftCardUsed={isGiftCardUsed}
                      />
                    </div>
                  </div>
                  <p className="text-zoyya-secondaryDarker mb-2 relative bottom-1">
                    Kod: {giftCard.code}
                  </p>
                  {giftCard.validFrom ? (
                    <p>
                      Vrijedi od:{' '}
                      {format(new Date(giftCard.validFrom), 'dd.MM.yyyy.')}
                    </p>
                  ) : null}
                  {giftCard.validTo ? (
                    <p>
                      Vrijedi do:{' '}
                      {format(new Date(giftCard.validTo), 'dd.MM.yyyy.')}
                    </p>
                  ) : null}

                  {giftCard.giftCardType === 'GIFTCARD' ? (
                    <>
                      <p className="mt-auto ">
                        Ukupna vrijednost:{' '}
                        <span className="font-medium">
                          {giftCard.value} {giftCard?.currency}
                        </span>
                      </p>
                      <p>
                        Preostala vrijednost:{' '}
                        <span className="font-medium">
                          {giftCard.balance} {giftCard?.currency}
                        </span>
                      </p>
                    </>
                  ) : (
                    <div className="mt-auto flex justify-between gap-4 ">
                      <ul className="list-inside list-disc">
                        {giftCard?.items?.map(item => {
                          return (
                            <li
                              key={item.name}
                            >{`${item.name} ${item.quantity}/${item.totalQuantity}  `}</li>
                          )
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {!isAppointmentModal ? (
                <div className="lg:absolute w-fit bottom-3 ml-auto right-3 mt-3">
                  <ButtonTW
                    variant="secondary"
                    size="small"
                    label="Vidi detalje"
                    icon={<icons.ArrowRight size="smaller" />}
                    iconRight
                    onClick={() =>
                      navigateTo.editGiftCard({ id: giftCard?.id })
                    }
                  />
                </div>
              ) : null}
            </div>
          )
        })
      ) : (
        <EmptyList title="Klijent nema poklon bonova" />
      )}
    </>
  )
}

export const Status = ({ giftCard, isGiftCardUsed }) => {
  return (
    <p
      className={`font-semibold ${
        isGiftCardUsed
          ? 'text-zoyya-accent5'
          : giftCard.status === 'ACTIVE'
          ? 'text-zoyya-accent2'
          : 'text-zoyya-accent5'
      }`}
    >
      {isGiftCardUsed
        ? 'Iskorišten'
        : giftCard.status === 'ACTIVE'
        ? 'Aktivan'
        : giftCard.status === 'EXPIRED'
        ? 'Istekao'
        : null}
    </p>
  )
}
