import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
  containsHTMLTags,
  formatPriceNumberWithDelimiter,
  getISODate,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { RouterOutput, trpc } from 'src/trpc'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import { format } from 'date-fns'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { Edit } from 'src/components/Icon/Icons/Edit'
import { useSdk } from 'src/sdk'
import { Button, icons } from 'src/components'
import { Column } from 'react-table'
import { DataTable } from 'src/sdk/tw/DataTable'
import Calendar from 'src/components/Icon/Icons/Calendar'
import moment from 'moment'
import { GiftCardChangelog } from './GiftCardChangelog'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import Tooltip from 'src/components/Tooltip/Tooltip'
import { EmptyList } from 'src/components/EmptyList/EmptyList'

type GiftCardScreenProps = {
  data: RouterOutput['giftCard_findOne']
  currency: string
  setIsPreviewMode: (value: boolean) => void
}

export const GiftCardScreen = (props: GiftCardScreenProps) => {
  const { navigateTo, t, isMobile, appServices } = useSdk()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState<'services' | 'usage' | 'log'>(
    props?.data?.giftCardType === 'GIFTCARD' ? 'usage' : 'services'
  )

  const tabs =
    props?.data?.giftCardType === 'GIFTCARD'
      ? [
          { key: 'usage', label: 'Aktivnost korištenja' },
          { key: 'services', label: 'Odobrene usluge' },
          { key: 'log', label: 'Log izmjena' },
        ]
      : [
          { key: 'services', label: 'Sadržaj paketa' },
          { key: 'usage', label: 'Aktivnost korištenja' },
          { key: 'log', label: 'Log izmjena' },
        ]

  const { data, currency, setIsPreviewMode } = props
  const params = useParams<{
    locationId: string
    orgId: string
    id: string
  }>()

  const trpcContext = trpc.useContext()
  const { locationId, orgId, id } = params

  const { data: giftCardUsage, isLoading: isLoadingGiftCardUsage } =
    trpc.giftCard_usage_findMany.useQuery({
      orgId: orgId,
      locationId: BigInt(locationId),
      giftCardId: BigInt(data?.id || 0),
    })
  const isGiftCardUsed =
    data?.giftCardType === 'GIFTCARD'
      ? data?.balance !== data?.value
      : !data?.items?.every(item => {
          const itemInTemplate = data?.items?.find(
            x => x.serviceId === item.serviceId
          )
          return itemInTemplate?.quantity === item.quantity
        })

  const resendClientEmailMutation =
    trpc.giftCard_resendClientEmail.useMutation()

  const handleResendClientEmail = async () => {
    try {
      const res = await resendClientEmailMutation.mutateAsync({
        giftCardId: BigInt(id),
        locationId: BigInt(locationId),
        orgId,
      })

      appServices.toast.success('Email je uspješno poslan')
    } catch (e) {
      appServices.toast.danger('Email nije uspješno poslan')
    }
  }

  useEffect(() => {
    trpcContext.giftCard_usage_findMany.invalidate({
      orgId: orgId,
      locationId: BigInt(locationId),
      giftCardId: BigInt(id),
    })
  }, [])

  return (
    <div className="h-full overflow-y-auto flex flex-col">
      <div className="pt-2.5 flex pb-3 lg:-pb-0 lg:flex-row flex-col w-full border-b border-zoyya-outline font-semibold items-center justify-between  bg-white z-10 px-[15px] lg:px-[20px]">
        <div className="lg:max-w-[80%] flex items-center">
          <div className=" [&>button]:px-0 mr-6 hidden lg:block">
            <ButtonTW
              variant="text"
              className="p-0"
              icon={<ArrowLeft />}
              onClick={() => history.goBack()}
            />
          </div>

          <div className="text-center lg:text-left">
            <div className="text-[20px]">{data?.name}</div>
            <div className="text-zoyya-secondaryDark font-normal flex items-center">
              <span className="mr-[10px]">{`Vrsta predloška: ${data?.giftCardTemplate?.name}`}</span>
            </div>
            {data?.giftCardTemplate?.status === 'DELETED' ? (
              <div className="text-zoyya-accent5 justify-center lg:justify-start font-normal flex items-center pb-[4px]">
                <icons.WarningTriangle size={'smaller'} color={'orange'} />
                <span className="mr-[10px] text-[12px]">
                  Predložak je obrisan
                </span>
              </div>
            ) : null}
            {/*   <div className="text-zoyya-secondaryDark font-normal">
              <span>{`Preostalo: ${formatPriceNumberWithDelimiter(
                data?.balance || 0
              )} ${currency}`}</span>
            </div> */}
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-[20px]">
            <span>Oznaka:</span>
            <span className="text-[18px] font-medium ml-[10px]">
              {data?.code}
            </span>
          </div>
          <ButtonTW
            label="Uredi"
            className={'font-semibold'}
            size="small"
            variant="primaryOutline"
            onClick={() => setIsPreviewMode?.(false)}
            icon={<Edit size={'size14'} stroke={2} />}
          />
        </div>
      </div>

      <div className="overflow-auto flex-1">
        <div className="flex flex-col-reverse lg:flex-row lg:justify-between">
          <div>
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg w-[140px] font-semibold">
                Vrijednost:
              </span>
              <span className="text-lg text-right min-w-[120px] ml-[10px]">{`${data?.value} ${currency}${
                data?.giftCardType === 'VOUCHER' && data?.value !== data?.price
                  ? ` (neto: ${formatPriceNumberWithDelimiter(
                      data?.price || 0
                    )} ${currency})`
                  : ''
              }`}</span>
            </div>
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg w-[140px] font-semibold">Popust:</span>
              <span className="text-lg text-right min-w-[120px] ml-[10px]">{`${formatPriceNumberWithDelimiter(
                parseFloat(data?.discountRate?.toString() || '0') * 100,
                false
              )}%`}</span>
            </div>

            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg w-[140px] font-semibold">Cijena:</span>
              <span className="text-lg text-right min-w-[120px] ml-[10px] w-[95px]">{`${formatPriceNumberWithDelimiter(
                data?.price
              )} ${currency}`}</span>
            </div>
            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg  w-[140px] font-semibold">
                Iskorišteno:
              </span>
              <span className="text-lg text-right min-w-[120px] ml-[10px] w-[95px] ">{`${formatPriceNumberWithDelimiter(
                data?.giftCardType === 'GIFTCARD'
                  ? data?.value - (data?.balance || 0)
                  : data?.price - (data?.balance || 0)
              )} ${currency}`}</span>
            </div>

            <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
              <span className="text-lg w-[140px] font-semibold">
                Preostalo:
              </span>
              <span className="text-lg min-w-[120px] ml-[10px] w-[95px] text-right">{`${formatPriceNumberWithDelimiter(
                data?.balance || 0
              )} ${currency}`}</span>
            </div>

            {data?.validTo ? (
              <div className="flex items-center mt-[15px] px-[15px] lg:px-[20px]">
                <span className="text-lg w-[140px] font-semibold">
                  Vrijedi do:
                </span>
                <span className="text-lg text-right min-w-[120px] ml-[10px]">
                  {format(data?.validTo, 'dd.MM.yyyy.')}
                </span>
              </div>
            ) : null}

            {data?.client ? (
              <div className="flex flex-col lg:flex-row lg:items-center mt-[15px] px-[15px] lg:px-[20px] overflow-hidden">
                <span className="mb-1 text-lg min-w-[120px] font-semibold">
                  Klijent:
                </span>
                <ClientSelect
                  showAddNewButton={false}
                  disabled
                  selectedItem={data?.client}
                />
              </div>
            ) : null}

            {!isGiftCardUsed && data?.client?.email ? (
              <div className="flex lg:items-center mt-2.5 lg:mt-[20px] px-[15px] lg:px-[20px] overflow-hidden">
                <span className="hidden lg:inline text-lg min-w-[140px]"></span>
                <Tooltip
                  position={'top'}
                  label="Klijent će u e-mailu dobiti informacije o pridruženom poklon bonu"
                >
                  <ButtonTW
                    label="Pošalji email klijentu"
                    variant="secondary"
                    type="button"
                    onClick={async e => {
                      await handleResendClientEmail()
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            {data?.description && data?.description !== '<p></p>' ? (
              <div className="flex flex-col lg:flex-row lg:items-center mt-[15px] px-[15px] lg:px-[20px]">
                <span className="mb-1 lg:mb-0 text-lg min-w-[140px] font-semibold">
                  Opis:
                </span>
                <div
                  className="text-md lg:ml-[10px] [&>ul]:m-1 [&>ul]:list-inside [&>ul]:list-disc [&>ul]:p-1"
                  dangerouslySetInnerHTML={{ __html: data?.description || '' }}
                ></div>
              </div>
            ) : null}
            {data?.terms ? (
              <div className="flex flex-col whitespace-pre-line lg:flex-row lg:items-center mt-[15px] px-[15px] lg:px-[20px]">
                <span className="mb-1 lg:mb-0 text-lg min-w-[140px] font-semibold">
                  Uvjeti korištenja:
                </span>
                {containsHTMLTags(data?.terms!) ? (
                  <div
                    className="text-md ml-[10px] [&>ul]:m-1 [&>ul]:list-inside [&>ul]:list-disc [&>ul]:p-1"
                    dangerouslySetInnerHTML={{
                      __html: data?.terms || '<p></p>',
                    }}
                  ></div>
                ) : (
                  <span className="text-md lg:ml-[10px]">{data?.terms}</span>
                )}
              </div>
            ) : null}
          </div>
          {data?.downloadGiftCardImgUrl || data?.downloadGiftCardQrCodeUrl ? (
            <div className="flex flex-col lg:min-w-[400px] items-center lg:items-end p-[15px]">
              {data?.downloadGiftCardImgUrl ? (
                <img
                  className="max-w-[350px]"
                  src={data?.downloadGiftCardImgUrl}
                />
              ) : null}

              {/* {data?.downloadGiftCardQrCodeUrl ? (
              <img
                className="max-w-[100px]"
                src={data?.downloadGiftCardQrCodeUrl}
              />
            ) : null} */}
            </div>
          ) : null}
        </div>

        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
        />
        {selectedTab === 'usage' ? (
          <UsageTab
            giftCardUsage={giftCardUsage}
            isLoadingGiftCardUsage={isLoadingGiftCardUsage}
          />
        ) : selectedTab === 'services' ? (
          <div className="p-4">
            <ServicesTab
              data={data?.items}
              value={data?.value}
              currency={currency}
              type={data?.giftCardType}
            />
          </div>
        ) : (
          <div className="flex p-4">
            <GiftCardChangelog giftCardId={data?.id} />
          </div>
        )}
      </div>
    </div>
  )
}
const GiftCardUsageItemMobileCard = (props: {
  item: RouterOutput['giftCard_usage_findMany'][0]
}) => {
  const { item } = props

  return (
    <div key={item?.id.toString()} className="rounded-lg border  p-4 ">
      <div className="flex flex-col space-y-2 ">
        <div className="flex items-center justify-between gap-2">
          <span className="text-sm">Datum korištenja:</span>
          <span className="font-medium text-right">
            {format(new Date(item?.ledgerDate), 'dd.MM.yyyy.')}
          </span>
        </div>
        <div className="flex items-center justify-between gap-2">
          <span className="text-sm">Datum termina:</span>
          <span className="font-medium text-right">
            {`${format(item?.ResourceBooking?.date!, 'dd.MM.yyyy.')} ${format(
              item?.ResourceBooking?.startTime!,
              'HH:mm'
            )}`}
          </span>
        </div>
        <div className="flex items-center justify-between gap-2">
          <span className="text-sm">Usluga:</span>
          <span className="font-medium text-right">
            {item?.ResourceBookingService?.Service?.name}
          </span>
        </div>
        <div className="flex items-center justify-between gap-2">
          <span className="text-sm">Iskorišteno:</span>
          <span className="font-medium text-right">{`${formatPriceNumberWithDelimiter(
            item?.creditAmount - item?.debitAmount
          )} ${item?.currencyId}`}</span>
        </div>
      </div>
    </div>
  )
}

const Tabs = ({ tabs, selectedTab, onSelectTab }) => {
  return (
    <div className="flex gap-3 lg:gap-10 lg:mt-[60px] mt-[30px] lg:pl-6 w-full bg-zoyya-grayLighter border-zoyya-text/10 border-1">
      {tabs.map(tab => (
        <div
          key={tab.key}
          onClick={() => onSelectTab(tab.key)}
          className={`flex-1 flex items-center justify-center lg:px-3 lg:flex-[unset] text-center py-3 cursor-pointer ${
            selectedTab === tab.key
              ? 'border-b-[3px] border-zoyya-primary'
              : 'border-b-[3px] border-transparent'
          }`}
        >
          <p>{tab.label}</p>
        </div>
      ))}
    </div>
  )
}

type ServicesTabProps = {
  data: any
  currency: string
  type: string
  value?: number
}
export const ServicesTab = ({
  data,
  currency,
  type,
  value,
}: ServicesTabProps) => {
  return (
    <>
      {/* desktop start */}
      <div className="hidden lg:block mt-[30px] px-[15px] lg:px-[20px]">
        <div className="bg-zoyya-grayLighter rounded-sm border border-zoyya-border pr-3 pl-[1.143rem]">
          <div className="rounded-md w-full">
            <div className="w-full flex  py-2">
              {type === 'GIFTCARD' ? (
                <>
                  <p className="w-[300px] text-sm text-zoyya-secondaryDark">
                    Vrsta usluge
                  </p>
                  <p className="mr-auto text-sm text-zoyya-secondaryDark">
                    Usluga
                  </p>
                  <div className="w-20"></div>
                </>
              ) : (
                <>
                  <p className="grow text-sm text-zoyya-secondaryDark">
                    Usluga
                  </p>
                  <p className="w-[70px] mr-[14px] text-sm text-zoyya-secondaryDark">
                    Ukupno
                  </p>
                  <p className="w-[70px] mr-[14px] text-sm text-zoyya-secondaryDark">
                    Preostalo
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Cijena
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Vrijednost
                  </p>
                  <p className="w-[40px] mr-[10px] text-center text-sm text-zoyya-secondaryDark">
                    %
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Neto
                  </p>
                  <p className="w-[70px] mr-[10px] text-sm text-zoyya-secondaryDark">
                    Iznos
                  </p>
                  <div className="w-10"></div>
                </>
              )}
            </div>

            {data?.length ? (
              data?.map(x => (
                <div
                  key={x?.id.toString()}
                  className="w-full flex py-2 border-t border-zoyya-border "
                >
                  {type === 'GIFTCARD' ? (
                    <>
                      <p className="w-[300px]">
                        {x?.serviceTypeId ? x?.name : ''}
                      </p>
                      <p className="mr-auto">
                        {x?.serviceId ? x?.name : 'Sve usluge'}
                      </p>
                      <div className="w-[20px]"></div>
                    </>
                  ) : x?.Service ? (
                    <>
                      <p className="grow text-[13px] mr-[10px]">{x?.name}</p>
                      <p className="min-w-[87px] text-[13px]">
                        {x?.totalQuantity || '0'}
                      </p>
                      <p className="min-w-[70px] text-[13px]">
                        {x?.quantity || '0'}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">
                        {`${
                          formatPriceNumberWithDelimiter(x?.Service?.price) ||
                          ''
                        }`}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">{`${formatPriceNumberWithDelimiter(
                        x?.quantity * Number(x?.Service?.price)
                      )}`}</p>
                      <p className="min-w-[40px] mr-[10px] text-center text-[13px]">
                        {formatPriceNumberWithDelimiter(
                          Number(x.discountRate) * 100,
                          false
                        ) || ''}
                      </p>
                      <p className="min-w-[70px] mr-[10px] text-[13px]">
                        {formatPriceNumberWithDelimiter(
                          Number(x?.Service?.price) -
                            roundNumberWithTwoDecimals(
                              Number(x?.Service?.price) *
                                Number(x?.discountRate)
                            )
                        )}
                      </p>
                      <p className="min-w-[80px] mr-[10px] text-[13px]">
                        {`${formatPriceNumberWithDelimiter(
                          (Number(x?.Service?.price) -
                            roundNumberWithTwoDecimals(
                              Number(x?.Service?.price) *
                                Number(x?.discountRate)
                            )) *
                            x?.quantity
                        )} ${currency}`}
                      </p>

                      <div className="w-[20px] min-w-[20px]"></div>
                    </>
                  ) : null}
                </div>
              ))
            ) : type === 'GIFTCARD' ? (
              <div className="w-full flex py-2 border-t border-zoyya-border ">
                <>
                  <p className="w-[300px]"></p>
                  <p className="mr-auto">{'Sve usluge'}</p>
                  <div className="w-[20px]"></div>
                </>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* dekstop end */}

      {/* mobile */}
      {type === 'GIFTCARD' ? (
        //giftcard allowed services
        <>
          {data?.length ? (
            <div className="">
              <table className="lg:hidden min-w-full rounded-lg bg-white p-4">
                <thead>
                  <tr className="bg-zoyya-lightBackground">
                    <th className="p-3 text-left font-medium">Vrsta usluge</th>
                    <th className="w-2/3 p-3 text-left font-medium">Usluga</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map(service => (
                    <tr key={service?.id} className="border-b">
                      <td className="p-3">
                        {service?.serviceTypeId ? service.name : ''}
                      </td>
                      <td className="p-3">
                        {service?.serviceId ? service.name : 'Sve usluge'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="py-5 text-center text-secondary-400 lg:hidden">
              Ovaj poklon bon se može iskoristiti za bilo koju uslugu/usluge u
              vrijednosti do {(data?.value || value) + ' ' + currency}
            </div>
          )}
        </>
      ) : (
        //voucher services
        <div className="flex flex-col gap-3 lg:hidden">
          {data?.length
            ? data?.map(service => (
                <div
                  key={service?.id.toString()}
                  className="cursor-pointer rounded-lg border p-4 "
                >
                  <h3 className="text-lg font-semibold">{service?.name}</h3>
                  <div className="mt-2 flex flex-col space-y-2 text-sm">
                    <div className="flex items-center justify-between">
                      <span>Ukupno:</span>
                      <span className="text-md font-medium">
                        {service.totalQuantity}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Preostalo:</span>
                      <span className="text-md font-medium">
                        {service.quantity}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Cijena:</span>
                      <span className="whitespace-nowrap text-md font-medium">
                        {`${formatPriceNumberWithDelimiter(
                          service.servicePrice
                        )} ${currency}`}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Vrijednost:</span>
                      <span className="whitespace-nowrap text-md font-medium">
                        {`${formatPriceNumberWithDelimiter(
                          service.quantity * service.servicePrice
                        )} ${currency}`}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Popust:</span>

                      <span className="text-md font-medium">
                        {formatPriceNumberWithDelimiter(
                          Number(service.discountRate) * 100,
                          false
                        ) + '%' || ''}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Iznos:</span>
                      <span className="whitespace-nowrap text-md font-medium">
                        {`${formatPriceNumberWithDelimiter(
                          service.quantity * service.giftCardPrice
                        )} ${currency}`}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      )}
    </>
  )
}

const UsageTab = ({ giftCardUsage, isLoadingGiftCardUsage }) => {
  const { navigateTo, t } = useSdk()
  const columns: Column<any>[] = useMemo(
    () => [
      {
        Header: 'Datum korištenja',
        accessor: 'ledgerDate',
        minWidth: 70,
        width: 70,
        Cell: props => (
          <p>
            {props.cell.row.original?.ledgerDate
              ? format(props.cell.row.original?.ledgerDate, 'dd.MM.yyyy.')
              : ''}
          </p>
        ),
      },
      {
        Header: 'Datum termina',
        accessor: 'bookingDate',
        minWidth: 70,
        width: 70,
        Cell: props => (
          <p>
            {props.cell.row.original?.ResourceBooking
              ? `${format(
                  props.cell.row.original?.ResourceBooking?.date,
                  'dd.MM.yyyy.'
                )} ${format(
                  props.cell.row.original?.ResourceBooking?.startTime,
                  'HH:mm'
                )}`
              : ''}
          </p>
        ),
      },
      {
        Header: 'Usluga',
        accessor: 'itemDescription',
        minWidth: 200,
        width: 200,
        Cell: props => (
          <p>
            {props.cell.row.original?.ResourceBookingService?.Service?.name}
          </p>
        ),
      },
      {
        Header: () => <div className="text-end pr-[20px]">{'Iskorišteno'}</div>,
        accessor: 'price',
        minWidth: 80,
        width: 80,
        Cell: props => (
          <p className="pr-[20px] text-end">{`${formatPriceNumberWithDelimiter(
            props.cell.row.original?.creditAmount -
              props.cell.row.original?.debitAmount
          )} ${props.cell.row.original?.currencyId}`}</p>
        ),
      },
      {
        Header: () => <div className=" text-center">{''}</div>,
        accessor: 'seeOnCalendar',
        minWidth: 50,
        width: 50,
        Cell: props => (
          <p className="text-center">
            <Button
              buttonType={'secondary'}
              iconComponent={<Calendar size={'smaller'} />}
              hasIconOnly
              size={'small'}
              onClick={event => {
                // format props.cell.row.original?.ResourceBooking.startTime to hour and minut string using date-fns
                const startTime = moment(
                  props.cell.row.original?.ResourceBooking?.startTime
                ).format('HH:mm')
                const selectedHour = startTime.split(':')
                navigateTo.dailyCalendarAppointment({
                  date: getISODate(
                    props.cell.row.original?.ResourceBooking?.startTime
                  ),
                  reservationId: props.cell.row.original?.ResourceBooking?.id,
                  hour: selectedHour[0],
                  minute: selectedHour[1],
                  type: 'appointment',
                })
              }}
              tooltip={t('translation.NewClientContainer.tooltip-openCalendar')}
            />
          </p>
        ),
      },
    ],
    []
  )

  return (
    <>
      <div
        className={`lg:flex hidden lg:min-h-[200px] lg:max-h-[600px] h-full w-full mt-[30px] px-[15px] lg:px-[20px]`}
      >
        <DataTable
          data={giftCardUsage || []}
          renderMobileCard={(item: any) => (
            <GiftCardUsageItemMobileCard item={item} />
          )}
          columns={columns}
          isLoading={isLoadingGiftCardUsage}
          emptyListTitle={'Nema zapisa o aktivnosti korištenja'}
          mobileCardHeight={150}
          tableRowHeight={50}
        />
      </div>
      <div className="flex flex-col p-4 gap-3 lg:hidden">
        {giftCardUsage?.length ? (
          giftCardUsage?.map(ledgerItem => (
            <GiftCardUsageItemMobileCard item={ledgerItem} />
          ))
        ) : (
          <EmptyList title="Nema zapisa o aktivnosti korištenja" />
        )}
      </div>
    </>
  )
}
